import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Регистрация права собственности | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Наша компания Алмаз оказывает услуги, которые направлены на оформление государственной регистрации заключаемого договора" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/registraciya-prava-sobstvennosti'
				name="Регистрация права собственности | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Наша компания Алмаз оказывает услуги, которые направлены на оформление государственной регистрации заключаемого договора"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Регистрация права собственности" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Регистрация права собственности"}
			
				html={

				`

				<p class="TextText">
				Наша компания Алмаз оказывает услуги, которые направлены на оформление государственной регистрации заключаемого договора, (т.е. договор купли-продажи или аренды, а также залога и так далее), или права собственности на весь объект недвижимости в установлении законом порядке. При всём этом, государственная регистрация права собственности всегда признаётся обязательной только для тех сделок, в отношениях которых требование по государственной регистрации будет прямо установлено законодательством. Мы обязательно обеспечим Вам очень грамотное решение вопросов, которые связаны с оформлением недвижимости, регистрацией права на недвижимость и регистрацией права собственности. Это сохранит всё Ваше время, а также Ваши нервы.
				</p>

				<p class="TextText">
				Регистрация права собственности является актом признания за собственником такого права распоряжения или же другого действия со всеми принадлежащими собственнику активами. Само понятие собственности объединяет всё движимое и недвижимое имущество, а также интеллектуальные, или нематериальные активы.
				</p>

				<p class="TextText">
				Регистрация права собственности производится физическими лицами и юридическими лицами, различными федеральными структурами и субъектами РФ, а также муниципальными образованиями.
				</p>

				<p class="TextText">
				Например, допустим, что у Вас есть собственность в Казани в виде квартиры. Вы оформили право по владения ею, то есть зарегистрировали право собственности, однако сами ею не распоряжаетесь, потому что передали на доверительном управлении какому-то определённому юридическому лицу. Процесс регистрации права собственности в Казани на Вашу квартиру компании, которая осуществляет доверительное управление, будет невозможным, так как она будет иметь право только распоряжаться недвижимостью в рамках имеющегося составленного договора с вами.
				</p>


				<p class="TextText">
					<b>Необходимые документы</b>
				</p>

				<p class="TextText">
				заявление правообладателя либо нотариуса (помощника нотариуса) о государственной регистрации права (оригинал);
				документ, удостоверяющий личность заявителя (оригинал);
				документ, подтверждающий полномочия представителя правообладателя (если от имени правообладателя действует его представитель) (оригинал и копия);
				свидетельство о праве на наследство (оригинал и копия);
				соглашение о разделе наследственного имущества (соглашение о выделении из наследства доли одного или нескольких наследников) (при заключении такого соглашения наследниками, в том числе после выдачи им свидетельств(а) о праве на наследство) (если соглашение совершено в простой письменной форме - оригинал, не менее 2 экз.; если соглашение нотариально удостоверено – не менее 2 экз., один из которых оригинал).
				</p>

				<p class="TextText">
				При проведении правовой экспертизы представленных документов государственный регистратор обязан принять необходимые меры по получению дополнительных сведений и (или) подтверждению подлинности документов или достоверности указанных в них сведений.

				Представление документа об уплате государственной пошлины вместе с заявлением о государственной регистрации прав и иными необходимыми для государственной регистрации прав документами не требуется. Заявитель вправе представить документ об уплате государственной пошлины в орган, осуществляющий государственную регистрацию прав, по собственной инициативе.
				</p>

				<p class="TextText">
				Если информация об уплате государственной пошлины отсутствует в Государственной информационной системе о государственных и муниципальных платежах и документ об уплате государственной пошлины не был представлен вместе с заявлением о государственной регистрации прав, документы, необходимые для государственной регистрации прав, к рассмотрению не принимаются.

				При непогашении долга юридическая компания поможет во взыскании дебиторской задолженности.

				Такие документы по истечении восемнадцати календарных дней с момента их поступления в случае наличия соответствующего указания в заявлении выдаются заявителю лично или направляются ему посредством почтового отправления с объявленной ценностью при его пересылке, описью вложения и уведомлением о вручении.
				</p>

				<p class="TextText">
				О доступности возможности получения Росреестром подтверждения об уплате государственной пошлины в порядке межведомственного взаимодействия уточняйте на сайте или в территориальном органе Росреестра.

				В случае, предусмотренном статьей 333.35 Налогового кодекса Российской Федерации, лицо, освобожденное от уплаты государственной пошлины, представляет документ, являющийся основанием для предоставления льготы (оригинал и копия).

				Не допускается осуществление государственной регистрации права на объект недвижимого имущества, который не считается учтенным в соответствии с Федеральным законом от 24.07.2007 № 221-ФЗ «О государственном кадастре недвижимости», за исключением случаев, предусмотренных федеральным законом.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
